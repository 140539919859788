<template>
  <div v-if="product.id" class="print-page padding-10mm">
    <div class="print-header">
      <div class="logo-wrapper">
        <div class="logo">
          <img src="@/assets/images/logo-print.png" />
        </div>
        <div class="slogan">
          <span>Noi facem tot! Doar pui pe foc!</span>
        </div>
      </div>
      <div class="description-title">
        <p>Pagina printata de pe Pefoc.ro</p>
      </div>
    </div>
    <div class="main-content content produs-detail">
      <div class="print-content">
        <h1 class="prod_title">
          {{ product.name + " - " + product.category_name }}
        </h1>
        <!-- <specifications :properties="product.property" /> -->
        <div class="specification-section">
          <div class="description">
            <div v-if="product.property.length" class="specification-title">
              Detalii privind produsul
            </div>
            <div
              v-for="(property, index) in product.property"
              :key="index"
              class="item-description"
            >
              <label>{{ property.name }}: </label>
              <span class="value">
                <span v-html="property.value"></span>
                <span v-html="property?.measure"></span>
              </span>
            </div>
          </div>
        </div>

        <!-- <div class="produs-content">
          <more-info :description="product.description" />
        </div> -->
      </div>
      <div class="left-wrapper">
        <div class="image-wrapper">
          <div class="img-inner">
            <img
              v-if="product.preview_image"
              :src="product.preview_image"
              alt="image"
              title="Image"
              loading="lazy"
            />
            <img
              v-else
              src="@/assets/images/product/default-thumb.jpg"
              alt="image"
              title="Image"
              loading="lazy"
            />
          </div>
        </div>
        <div class="comanda-wrapper">
          <div class="produs-pret-vechi">
            Pret vechi: {{ product.offers[0].old_price }} lei -
            {{ priceInEuro(product.offers[0].old_price_value) }} &euro;
          </div>

          <div class="produs-pret">
            Pret: {{ product.offers[0].price }} lei -
            {{ priceInEuro(product.offers[0].price_value) }} &euro;;
          </div>
        </div>
      </div>
    </div>
    <div v-if="warranty" class="garantie">
      <div class="garantie-icon-wrapper">
        <img src="@/assets/images/garantie.png" />
        <span class="garantie">Garantie {{ warranty.description }} ani</span>
      </div>
      <p class="prod-garantee">
        Garantia se ofera in cazul persoanelor care executa un montaj corespunzator
        conform recomandarilor Pefoc, sau cu personal specializat si autorizat in acest
        scop!
      </p>
    </div>
  </div>

  <div v-if="product.description" class="print-page padding-10mm">
    <div class="print-header">
      <div class="logo-wrapper">
        <div class="logo">
          <img src="@/assets/images/logo-print.png" />
        </div>
        <div class="slogan">
          <span>Noi facem tot! Doar pui pe foc!</span>
        </div>
      </div>
      <div class="description-title">
        <p>Pagina printata de pe Pefoc.ro</p>
      </div>
    </div>
    <div class="main-content content produs-detail">
      <div class="print-content-description">
        <div class="produs-content">
          <div class="options-title">Mai multe informatii privind produsul</div>
          <div v-html="product.description"></div>
        </div>
      </div>
    </div>
    <div v-if="warranty" class="garantie">
      <div class="garantie-icon-wrapper">
        <img src="@/assets/images/garantie.png" />
        <span class="garantie">Garantie {{ warranty.description }} ani</span>
      </div>
      <p class="prod-garantee">
        Garantia se ofera in cazul persoanelor care executa un montaj corespunzator
        conform recomandarilor Pefoc, sau cu personal specializat si autorizat in acest
        scop!
      </p>
    </div>
  </div>

  <div class="print-page">
    <div class="print-header">
      <div class="logo-wrapper">
        <div class="logo">
          <img src="@/assets/images/logo-print.png" />
        </div>
        <div class="slogan">
          <span>Noi facem tot! Doar pui pe foc!</span>
        </div>
      </div>
      <div class="description-title">
        <p>Pagina printata de pe Pefoc.ro</p>
      </div>
    </div>
    <h3 v-if="product.product_sketch">Poza schita produs</h3>
    <div class="main-content">
      <img
        v-if="product.product_sketch"
        :src="product.product_sketch"
        alt="image"
        title="Image"
        loading="lazy"
      />
      <!-- <img v-else
                src="@/assets/images/product/default-thumb.jpg"
                alt="image"
                title="Image"
                loading="lazy"
            /> -->
    </div>
  </div>
</template>

<script>
// import Specifications from "@/components/product_page/Specifications.vue";
import { shopMixin } from "@/mixins/shopMixin";

export default {
  data() {
    return {
      // warranty: null,
    };
  },
  computed: {
    product() {
      console.log(this.$store.getters["shop/product"]);
      return this.$store.getters["shop/product"];
    },
    warranty() {
      this.product?.property.find((warrinty) => {
        return warrinty.code === "garantie";
      });
      return null;
    },
  },
  mixins: [shopMixin],
  // watch: {
  //     product() {
  //         this.$nextTick(() => {
  //             window.print();
  //         });
  //     }
  // },
  components: {
    // Specifications,
  },

  created() {
    this.$store.dispatch("shop/getProduct", this.$route.params.slug);
  },

  mounted() {
    //  window.print();

    setTimeout(function () {
      window.print();
    }, 2500);

    // window.onfocus=function(){ window.close();}
  },
};
</script>

<style scoped>
@font-face {
  font-family: ShonarBangla;
  src: url("~@/assets/fonts/Shonar.ttf") format("truetype");
  font-display: auto;
}

.produs-content >>> iframe {
  display: none;
}

.produs-content >>> img {
  display: none !important;
}

.print-page {
  width: 210mm;
  min-height: 297mm;
  padding: 20mm;
  margin: 10mm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  .print-page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}

h1 {
  font-family: ShonarBangla;
}

.print-content {
  width: 60%;
  float: left;
  position: absolute;
  left: 0;
}

.print-content-description {
  width: 100%;
  float: left;
  position: absolute;
  left: 0;
}

.logo-wrapper {
  float: left;
  width: 200px;
}

.description-title {
  width: 300px;
  right: 0;
  top: 150px;
  color: #d85c32;
  text-align: right;
  position: absolute;
}

.print-header {
  float: left;
  width: 100%;
  position: relative;
  height: 210px;
}

.print-content-wrapper {
  float: left;
  width: 100%;
}

.left-wrapper {
  float: left;
  width: 38%;
  position: absolute;
  right: 0;
}

.image-wrapper {
  width: 98%;
  display: inline-block;
  border: 1px solid #d85c32;
  margin-top: 5px;
  position: relative;
}

.corner-image {
  /* background-image: url('/assets/img/sys/image_plus.png'); */
  position: absolute;
  left: 0px;
  height: 25px;
  width: 25px;
}

.img-inner {
  padding-bottom: 10px;
  overflow: hidden;
}

.comanda-wrapper {
  float: left;
  padding-top: 10px;
  width: 100%;
}

.slogan {
  color: #d83300;
  text-align: center;
}

.logo {
  text-align: center;
}

.img-inner img {
  width: 96%;
  margin: 2%;
}

label {
  display: inline;
  font-weight: bold;
  color: #111;
  padding-right: 5px;
  float: left;
  margin-bottom: 5px;
}

.value {
  display: inline;
  font-weight: bold;
  color: #858594;
}

.item-description {
  font-size: 1em;
  margin-bottom: 0px;
  float: left;
  width: 100%;
}

p {
  margin: 0;
  margin-bottom: 5px;
}

h1.prod_title {
  color: #111;
}

.description {
  width: 100%;
  float: left;
}
.produs-description {
  float: left;
  width: 100%;
}

.automatic_description {
  float: left;
  width: 100%;
}

.garantie {
  float: left;
  width: 100%;
  position: relative;
}

span.garantie {
  font-size: 20px;
  font-family: "ShanarBangla";
  color: #d86a31;
  position: absolute;
  padding: 5px 10px;
  float: left;
  width: 50%;
  top: 1.5em;
  left: 35px;
}

.garantie img {
  float: left;
  height: 55px;
}

.garantie-icon-wrapper {
  float: left;
  width: 100%;
  position: relative;
}

.prod-garantee {
  float: left;
  font-size: 0.8em;
}

.produs-pret,
.produs-pret-vechi {
  font-size: 1em;
  color: #d86a31;
  float: left;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: Arial;
}

.main-content {
  float: left;
  width: 100%;
  clear: both;
  position: relative;
}

.content.produs-detail {
  float: left;
  width: 100%;
  height: 650px;
  position: relative;
}

.main-content > img {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 20px;
}

.print-last-page {
  width: 96%;
  float: left;
}

.produs-pret-vechi {
  text-decoration: line-through;
}

@media print {
  @page {
    size: a4;
  }
}
</style>
